<template>
  <div>
    <!-- 标题 -->
    <!-- <div>
      <van-nav-bar title="资讯详情" />
    </div> -->
    <!-- 内容 -->
    <div class="essay">
      <div class="essayTitle"
        style="font-size: 18px;">{{ list.Title }}</div>
      <div class="rikind">{{ list.TKName }}</div>
      <!-- <div v-if="list.ThematicUrl"
        class="essayImg">
        <img :src="list.ThematicUrl"
          alt=""
          @click="thematic(list.ThematicUrl)" />
      </div> -->
      <p class="essayContent"
        v-html="list.Content"
        @click="imageEnlargement"></p>
      <div class="video"
        v-if="list.Video">
        <video style="width: 100%"
          :src="list.Video"
          controls="controls">
          您的浏览器不支持 video 标签。
        </video>
      </div>
      <div class="rikind"
        style="text-align: right;">阅读：{{ list.ReadNum }}</div>
      <!-- <div id="demo"
        class="m-pdf"></div> -->

      <!-- <iframe style="width:100%;height:78vh;" :src="pdf"></iframe> -->
    </div>
  </div>
</template>
<script>
import { ImagePreview } from 'vant'
import { WeGetRealInfo } from "@/api/RealInfo";
import { setOpenId, getOpenId } from "@/utils/auth";
import Pdfh5 from "pdfh5";
export default {
  data () {
    return {
      list: {},
      pdf: '',
      pdfh5: '',
    };
  },
  methods: {
    thematic (e) {
      ImagePreview({
        images: [e], // 传递的是图片集数组
        startPosition: 0, // 图片索引位置
        closeable: true, // 是否显示关了按钮
        showIndicators: true, // 是否显示轮播指示器
        loop: false // 不设置为false,首图和尾部图不能正常加载
      })
    },
    // 富文本图片内容 点击放大
    imageEnlargement (e) {
      if (e.target.nodeName === 'IMG') {
        ImagePreview({
          images: [e.target.src], // 传递的是图片集数组
          startPosition: 0, // 图片索引位置
          closeable: true, // 是否显示关了按钮
          showIndicators: true, // 是否显示轮播指示器
          loop: false // 不设置为false,首图和尾部图不能正常加载
        })
      }
    },
    // 获取资讯详情 https://api.qxzhqm.cn//upload/files/2022/3/20211217-老城区民营经济综合服务平台（PC端）(1)(1).docx
    getRealInfo () {
      WeGetRealInfo({ riId: this.$route.params.Id, openID: getOpenId() }).then((res) => {
        this.list = res.data.data;
        // axios.default.baseURL = 'https://api.qxzhqm.cn//upload/files/2022/3/ThingJS-X应用模板制作标准.pdf'
        // this.pdf = axios.default.baseURL + '/upload/files/2022/3/ThingJS-X应用模板制作标准.pdf'
        this.pdf = '/upload/files/2022/3/ThingJS-X应用模板制作标准.pdf'
        // this.pdf = '/upload/files/2022/3/20211217-老城区民营经济综合服务平台（PC端）(1)(1).docx'
        console.log(this.pdf);
        this.pdfh5 = new Pdfh5("#demo", {
          pdfurl: '../api' + this.pdf
        });
        //监听完成事件
        this.pdfh5.on("complete", function (status, msg, time) {
          console.log("状态：" + status + "，信息：" + msg + "，耗时：" + time + "毫秒，总页数：" + this.totalNum)
        })
        // this.list.Content = this.list.Content.replace(/<.*?>/ig,"")
        // console.log(this.list.Content);
      });
      this.activated()
    },

    activated () {
      var _this = this;
      _this.uuid = _this.$route.query.uuid;
      var shareUrl = global.BASE_SHARE_URL + 'grade?uuid=' + _this.uuid;
      this.$wxShare.updateWxShareConfig({
        title: '标题',
        desc: '简介',
        link: shareUrl
      });
    }
  },
  mounted () {
    this.getRealInfo();
  },
};
</script>
<style>
.essay .essayContent img {
  display: block;
  width: 100%;
}
.pageNum-num {
  display: none;
}
.m-pdf img {
  width: 100%;
}
</style>
